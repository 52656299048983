@use "sass:meta";
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@include meta.load-css('components/fileUpload.scss');
@include meta.load-css('components/popper.scss');
@include meta.load-css('components/listItems.scss');
@include meta.load-css('components/search.scss');
@include meta.load-css('components/statusBar.scss');
@include meta.load-css('components/clientSideError.scss');
@include meta.load-css('pages/shared/index.scss');
@include meta.load-css('components/login.scss');
@include meta.load-css('pages/newPassword.scss');

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Aligns the footer to the bottom */
#__next {
  min-height:      100vh;
  display:         flex;
  flex-direction:  column;
  justify-content: space-between;
}

* {
  box-sizing: border-box;
}

.unselectable {
  user-select: none;
}

.hyperlinked {
  color: blue;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: brown;
  }
}

.text-center {
  text-align: center;
}

.border-shadow {
  box-shadow:   0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.element--disabled {
  opacity: 0.5;
}

.textfield-empty {
  input {
    background: repeating-linear-gradient(
                  45deg,
                  #FFFFFF,
                  #FFFFFF 10px,
                  #dedede 10px,
                  #dedede 20px
                ) !important;
  }
}

@include meta.load-css('responsive.scss');
