.file-upload {
  min-height:     15em;
  max-width:      30em;
  border-radius:  10px;
  background-color:  #f7f7f7;

  &__box {
    height:            inherit;
    width:             inherit;
  }

  &__list {
    padding-left:   1em;
    padding-top:    1em;
    padding-bottom: 1em;
  }

  &__header {
    background-color: #e5f6fd;
    font-weight: bold;
  }
  
  .file-item {
    padding: 1em !important;
  }

  .file-extension {
    display:      inline-block;
    background-color: #f4f4f4;
    padding:      0.2em;
    margin-left:  1em;
    margin-right: 1em;
    border-radius: 4px;
    box-shadow:   3px 3px 0 rgba(0, 0, 0, 0.1);
  }
}

  .compact-item {
    padding-top: 0.2em !important;
    font-size: x-small;
  }
