.page-new-password {
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: 850px) {
      position: relative;
      transform: translate(0%, 0%);
      left: 0;
    }
  }

  .container {
    @media only screen and (max-width: 850px) {
      border: none;
      padding: 1em;
    }

    max-width: 800px;
    border: solid;
    border-color: #1169c2;
    border-radius: 10px;
    padding: 5em;
    background-color: #FFFFFF;
  }

  .shadow {
    @media only screen and (max-width: 850px) {
      box-shadow: none;
    }

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
