.component-cserror {
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container {
    border: solid;
    border-color: #1169c2;
    border-radius: 10px;
    padding: 5em;
    background-color: #1976d2;
    color: white;
  }

  .shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .element {
  border-radius: 100%;
  display: inline-block;
  margin: 0 auto;

    @for $i from 1 through 59 {
      &.element:nth-child(#{$i}) {
        background: #1169c2;
        animation: spin random(20)+s infinite;
      }
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
