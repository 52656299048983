.component-search {
  width:     400px;
  text-align: center;
  padding:   1em;

  &--filter-container {
    z-index:          100;
    padding:          0.5em;
    width:            250px;
    border-radius:    5px;
    background-color: #f5f2f2;
    box-shadow:       0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
  }

  &--filter-footer {
    text-align: center;
    font-size: 18px;
  }
}
