.component-status-bar {
  &--header-label {
    position: absolute;
    transform: translateY(20px);
    font-size: large;
    padding-left: 0.5em;
  }

  &--grid {
    transform: translateX(-15%);
    text-align: center;
  }
}
