$lg: 1430px;
$md: 900px;
$sm: 800px;
$xs: 580px;

/* Large 1430px */
@media all and (max-width: $lg) and (min-width: $md) {
  .MuiSlider-root.MuiSlider-marked {
    width: 100%;
    margin: 2em;
    margin-bottom: 100px;
    
    & > .MuiSlider-markLabel {
      white-space: normal;
      padding: 3px;
      max-width: 80px;
      text-align: center;
    }
  }

  .view-changer {
    width: 85%;
    height: auto !important;
    padding: 0 3em;
    margin-top: 3em !important;
    margin-bottom: 4em;
    margin-left: auto;
    margin-right: auto;

    > .MuiBox-root {
      margin: 0 1.5em;

      .component-status-bar--grid {
        span {
          font-size: .8em !important;
        }

        p {
          font-size: .5em;
        }
      }
    }

    .MuiButtonBase-root.MuiCardActionArea-root {
      height: auto;
      padding: 1em;
    }
  }
}

/* Medium 900px */
@media all and (max-width: $md) {
  .broker-index.page-items-container, .broker-index.page-controls {
    margin: 2em .2em;
    padding-left: .5em;
    padding-right: .5em;
  }

  .MuiSlider-root.MuiSlider-marked {
    width: 100%;
    margin: 1em;
    margin-bottom: 40px;

    & > .MuiSlider-markLabel {
      display: none;
    }

    & > .MuiSlider-markLabel:nth-child(1 of .MuiSlider-markLabelActive) {
      display: inline-block;
    }

    & > .MuiSlider-markLabel {
      left: 50% !important;
      transform: translate(-50%);
      &:before {
        content: 'Current Step:';
        display: inline-block;
        padding-right: 2px;
        font-weight: 600;
      }
    }

    & > .MuiSlider-mark {
      transform: scale(0.6) translate(0, -80%);
    }
  }

  .view-changer {
    width: 90%;
    height: auto !important;
    padding: 0 3em;
    margin-top: 3em !important;
    margin-bottom: 4em;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column !important;

    .MuiButtonBase-root.MuiCardActionArea-root {
      height: auto;
      padding: 1em;
    }

    > .MuiBox-root {
      width: 100%;
      margin-bottom: 2em;
    }
  }

  #status-bar.top-nav-status {
    .component-status-bar--grid > span:first-child {
      font-size: 50px !important;
    }
    .component-status-bar--header-label {
      font-size: 15px;
    }
  }

  .legend-list {
    flex-direction: column !important;
  }
}

@media screen and (max-width: $xs) {
  #status-bar.top-nav-status {
    .component-status-bar--grid {
      display: flex;
      flex-direction: column;
    }

    .component-status-bar--grid>span:first-child {
      font-size: 20px !important;
    }

    .component-status-bar--header-label {
      font-size: 10px;
      position: static;
      transform: none;
    }
  }
  .view-changer {
    > .MuiBox-root {
      .component-status-bar--grid {
        span {
          font-size: .8em !important;
        }

        p {
          font-size: .5em;
        }
      }
    }
  }
}