.broker-index {
  &.page-controls {
      margin:        3em;
      padding-left:  4em;
      padding-right: 4em;
      display:       flex;
    }

  &.page-items-container {
    margin:        3em;
    padding-left:  4em;
    padding-right: 4em;
    display:       block;
  }

  &.page-search {
    width: 100%;
  }

  &.button-dropdown-options {
    margin-left: 0.5em !important;
  }
}
